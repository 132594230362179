<template>
  <base-label class="flex items-start">
    <input
      type="checkbox"
      v-model="model"
      class="hidden"
      @change="
        event =>
          emit('update:modelValue', (event?.target as HTMLInputElement).checked)
      "
    />
    <icon-checkedbox class="w-6 h-6 flex-shrink-0" v-show="model" />
    <icon-uncheckedbox class="w-6 h-6 flex-shrink-0" v-show="!model" />
    <div class="ml-4">
      <slot />
    </div>
  </base-label>
</template>

<script lang="ts" setup>
import IconCheckedbox from '@/components/icons/IconCheckedbox.vue'
import IconUncheckedbox from '@/components/icons/IconUncheckedbox.vue'
import { ref, watch } from 'vue'

const props = defineProps<{ modelValue: boolean }>()

const model = ref<boolean>(props.modelValue)

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()

watch(
  () => props.modelValue,
  newValue => {
    model.value = newValue
  }
)
</script>
