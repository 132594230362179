import { defineStore } from 'pinia'
import sample from 'lodash/sample'
import { config } from '@/constants'
import { getHeaders } from './helpers'
import store from '@/store'
import { ref, computed } from 'vue'

export const useNeighborhoodsStore = defineStore('neighborhoods', () => {
  const all = ref<Strapi.Neighborhood[]>([])
  const current = ref<Strapi.Neighborhood[]>([])

  const randCurrent = computed<Strapi.Neighborhood>(() => sample(current.value))
  const randAll = computed<Strapi.Neighborhood>(() => sample(all.value))
  const allSlugs = computed(() =>
    all.value.map(neighborhood => neighborhood.slug)
  )
  const otherNeighborhoods = computed(() =>
    all.value?.filter(neighborhood => !current?.value?.includes(neighborhood))
  )

  // This selects a featured neighborhood from either the guest's neighborhood(s)
  // or from all available neighborhoods if the guest has no assigned neighborhoods
  const featuredNeighborhood = computed(() => {
    return current.value?.length > 0 ? randCurrent.value : randAll.value
  })

  async function fetchAndSetNeighborhoods() {
    const baseUrl = config.cms.apiUrl
    const url = new URL('/api/neighborhoods?populate=properties', baseUrl)
    const headers = await getHeaders()

    const response = await fetch(url.toString(), { headers }) //handle error somehow
    const { data } = await response.json()

    all.value = data

    // Access vuex store
    const acronym = store.getters['auth/currentProfile'].property?.acronym

    let currentNeighborhoods: Strapi.Neighborhood[]
    if (acronym) {
      currentNeighborhoods = all.value.filter(neighborhood =>
        neighborhood.properties?.find(property => property.acronym === acronym)
      )
      current.value = currentNeighborhoods
    }
  }

  return {
    all,
    current,
    randCurrent,
    randAll,
    allSlugs,
    featuredNeighborhood,
    otherNeighborhoods,
    fetchAndSetNeighborhoods,
  }
})
