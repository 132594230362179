import { mapState, mapGetters, mapActions } from 'vuex'
import store from '@/store'

export const authComputed = {
  ...mapState('auth', {
    currentUser: state => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const authMethods = mapActions('auth', [
  'logIn',
  'magicLogIn',
  'logOut',
  'signUp',
])

export const alertMethods = mapActions(['setAlert', 'resetAlert'])

export const refreshJWToken = async () => {
  const user = await store.dispatch('auth/validateToken')
  if (user) {
    const jwtToken = user.jwtToken
    return jwtToken
  }
}

export const getHeaders = async () => {
  const headers = new Headers()
  const jwtToken = store?.getters['auth/currentUser']?.jwtToken
  const expiration = JSON.parse(atob(jwtToken.split('.')[1])).exp
  const currentTime = Date.now() / 1000
  const expired = expiration < currentTime

  if (expired || !jwtToken) {
    const newToken = await refreshJWToken()
    headers.append('Authorization', newToken)
    return headers
  }

  if (jwtToken) {
    headers.append('Authorization', jwtToken)
  }

  return headers
}

export const mergeArrayWithUniqueDocumentId = (
  existingArray: Strapi.CityGem[],
  incomingArray: Strapi.CityGem[]
) => {
  const existingIds = new Set(existingArray.map(obj => obj.documentId))

  // Add only objects with unique documentId
  const filteredIncoming = incomingArray.filter(
    obj => !existingIds.has(obj.documentId)
  )

  return [...existingArray, ...filteredIncoming]
}
