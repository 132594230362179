import { useNeighborhoodsStore } from '@/store/neighborhoods'
import { storeToRefs } from 'pinia'

export const state = {
  neighborhood: undefined, // need to set default neighborhood for non instay guest
  cityGems: {
    category: 'all',
    tags: new Set([]),
    showMySavedCityGems: false,
  },
}

export const getters = {
  neighborhood(state) {
    return state.neighborhood
  },
  cityGemsFilters(state) {
    return state.cityGems
  },
  cityGemsCategory(state) {
    return state.cityGems.category
  },
  tags(state) {
    return state.cityGems.tags
  },
  showMySavedCityGems(state) {
    return state.cityGems.showMySavedCityGems
  },
}

export const mutations = {
  SET_NEIGHBORHOOD_FILTER(state, neighborhood) {
    state.neighborhood = neighborhood
  },
  SET_CITYGEMS_FILTER(state, filters) {
    state.cityGems = filters
  },
  SET_TAGS_FILTER(state, tags) {
    state.cityGems.tags = tags
  },
  TOGGLE_TAG(state, tag) {
    state.cityGems.tags.has(tag)
      ? state.cityGems.tags.delete(tag)
      : state.cityGems.tags.add(tag)
  },
  SET_SHOW_MY_SAVED_CITY_GEMS(state, bool) {
    state.cityGems.showMySavedCityGems = bool
  }
}

const usePiniaNeighborhoodStore = () => {
  const store = useNeighborhoodsStore()
  const { all, randCurrent, randAll, allSlugs } = storeToRefs(store)

  return { randCurrent, randAll, allSlugs, all }
}

export const actions = {
  async setNeighborhood({ commit, dispatch }, neighborhood = undefined) {
    commit('SET_NEIGHBORHOOD_FILTER', neighborhood)
    dispatch('resetTagFilter')
  },

  async setCityGemsFilter(
    { commit },
    cityGemsFilter = {
      category: 'all',
    }
  ) {
    commit('SET_CITYGEMS_FILTER', { cityGemsFilter })
  },

  async setCityGemsCategory({ commit }, category) {
    commit('SET_CITYGEMS_FILTER', { ...state.cityGems, category })
  },

  setRandomNeighborhood({ commit, dispatch, rootGetters }) {
    const currentNeighborhoods = rootGetters['neighborhoods/current']
    dispatch('resetTagFilter')

    if (!currentNeighborhoods) {
      const allNeighborhoods = rootGetters['neighborhoods/all']

      const randomlyChosenNeighborhood =
        allNeighborhoods[Math.floor(Math.random() * allNeighborhoods.length)]
      commit('SET_NEIGHBORHOOD_FILTER', randomlyChosenNeighborhood)
      return
    }

    const randomlyChosenNeighborhood =
      currentNeighborhoods[
      Math.floor(Math.random() * currentNeighborhoods.length)
      ]
    commit('SET_NEIGHBORHOOD_FILTER', randomlyChosenNeighborhood)
  },

  setTagFilter({ commit, dispatch }, tags) {
    dispatch('setCityGemsCategory', 'all')
    commit('SET_TAGS_FILTER', tags)
  },
  resetTagFilter({ commit, dispatch }) {
    dispatch('setCityGemsCategory', 'all')
    commit('SET_TAGS_FILTER', new Set())
  },
  setShowMySavedCityGems({ commit }, showMySavedCityGems: boolean) {
    commit('SET_SHOW_MY_SAVED_CITY_GEMS', showMySavedCityGems)
  },
  toggleShowMySavedCityGems({ commit, state }) {
    commit('SET_SHOW_MY_SAVED_CITY_GEMS', !state.cityGems.showMySavedCityGems)
  },
  async setDefaultNeighborhood({ commit, dispatch, rootGetters }) {
    dispatch('resetTagFilter')

    const { randCurrent, randAll } = usePiniaNeighborhoodStore()
    if (rootGetters['auth/currentProfile']?.property) {
      commit('SET_NEIGHBORHOOD_FILTER', randCurrent.value)
    } else {
      commit('SET_NEIGHBORHOOD_FILTER', randAll.value)
    }
  },
  toggleTag({ dispatch, commit }, tag: string) {
    dispatch('setCityGemsCategory', 'all')
    commit('TOGGLE_TAG', tag)
  },
  setNeighborhoodBySlug({ commit, dispatch }, neighborhoodSlug) {
    const { allSlugs, all } = usePiniaNeighborhoodStore()

    if (
      neighborhoodSlug === state.neighborhood?.slug ||
      !neighborhoodSlug ||
      !allSlugs.value.includes(neighborhoodSlug)
    ) {
      return
    }

    dispatch('resetTagFilter')
    const neighborhoods = all.value
    const selectedNeighborhood = neighborhoods.find(
      neighborhood =>
        neighborhood.slug.toUpperCase() === neighborhoodSlug.toUpperCase()
    )
    commit('SET_NEIGHBORHOOD_FILTER', selectedNeighborhood)
  },
}
